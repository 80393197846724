import * as React from "react";
import * as styles from "../../styles/global.module.css";

import { Box, Heading, VStack, Text } from "@chakra-ui/react";

import { FunctionComponent } from "react";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";
import useAuth from "../components/useAuth";

const UsePolicy: FunctionComponent = () => {
  const { isCyprusMerchant, isSyntechUser } = useAuth();
  return (
    <>
      <Layout>
        <Box maxW="1280px" margin="0px auto" pt="120px" pb="80px" px="20px">
          <VStack pb="48px">
            <Box
              className={styles.divider}
              w="1.5rem"
              height="0.13rem"
              color="red.500"
            />
            <Box>
              <Heading as="h1" fontWeight="400" className={styles.FadeHeading}>
                Website Use Policy
              </Heading>
            </Box>
          </VStack>
          <VStack gap="1.25rem" alignItems="flex-start">
            <Box>
              <p>
                This Web site Use Policy sets out the general terms and
                conditions governing use of our web site. PLEASE READ THE
                FOLLOWING TERMS AND CONDITIONS CAREFULLY. Your continued
                accessing of and use of this web site shall be deemed to be
                acceptance of the terms and conditions set out in this Policy.
                If you do not accept the terms of this Policy, do not use this
                web site
              </p>
              <p>&nbsp;</p>
              <p>
                PLEASE NOTE THAT THESE TERMS REGULATE USE OF THE WEBSITE ONLY
                AND ARE NOT A SUBSTITUTE FOR ANY CUSTOMER AGREEMENT THAT YOU
                ENTER INTO WITH DOCTOR MEDICA.
              </p>
            </Box>
            <Box>
              <Box>
                <b>1. Web site Operation.</b> 
                {isCyprusMerchant ? (
                  <p>
                    The website (www.doctormedica.co) is operated by Syntech Solutions Kft., VAT: HU32153891,
                    Address: 1203 Budapest, Kinizsi utca 20. Fz. 1. Ajtó, Hungary.
                  </p>
                ) : (
                  <span>
                    This web site is operated by Doctor Medica
                    ("doctormedica.co"). It is our goal to provide you with fast
                    and efficient processing of requests for prescription
                    fulfillment services. doctormedica.co is not a pharmacy, but
                    works with duly licensed pharmacies and dispensaries in many
                    jurisdictions, including Europe, Asia and throughout the
                    world, to facilitate customer requests for affordable
                    medications.{" "}
                  </span>
                )}
              </Box>
            </Box>
            <Box>
              <Box>
                <b>2. No Advice.</b>
                <span>
                  {" "}
                  Any information provided on the doctormedica.co web site is
                  for information purposes only and is not a substitute for
                  specific advice. Please always ensure that you consult a
                  medical doctor for medical advice, diagnosis and treatment –
                  no information on this web site should be relied on for this
                  purpose. Carefully read the labels and all information
                  provided by the product manufacturer and fulfilling pharmacy
                  and/or dispensary before using any product.
                </span>
              </Box>
            </Box>
            <Box>
              <Box>
                <b>3. Collection of Information.</b>
                <span>
                  {" "}
                  In order to establish your customer account, all customers
                  must enter into a customer agreement as part of the ordering
                  process. It will be necessary for doctormedica.co to collect
                  detailed personal information, which must be transmitted to
                  certain third parties to facilitate the processing of your
                  request. All personal information maintained by
                  doctormedica.co will be handled in accordance with our Privacy
                  Policy and by providing personal information to
                  doctormedica.co, you also agree to these terms and conditions.
                </span>
              </Box>
            </Box>
            <Box>
              <Box>
                <b>4. Prohibited Transactions.</b>
                <span>
                  {" "}
                  The provision of information, products or services on our web
                  site does not constitute an offer to sell any such product or
                  service, but indicates that such products or services are
                  available for sale to appropriate patients through licensed
                  pharmacies and dispensaries under agreement with
                  doctormedica.co. The terms and conditions applicable to a
                  product or service will be determined at the time of sale.
                  doctormedica.co reserves the right to deny a customer request
                  for products or services, including where the advertising of
                  the products or services or their notice for sale may be
                  deemed to be unlawful. All customers who use the web site are
                  responsible to ensure compliance with applicable laws,
                  including without limitation import laws, regulations and
                  restrictions in force in their jurisdiction.
                </span>
              </Box>
            </Box>
            <Box>
              <Box>
                <b>5. User Names and Passwords.</b>
                <span>
                  {" "}
                  Each customer is responsible for the use of his/her
                  established customer account, and to keep his/her user name
                  and password private and confidential. User names and
                  passwords may not be shared with or used by any third party.
                  Only the identified customer may access his/her customer
                  account. Use of a user name and password is sufficient
                  evidence of identity to permit doctormedica.co to collect
                  certain information from you to process your request for
                  products and/or services. Should a customer's user name and/or
                  password be disclosed to any third party, the customer must
                  immediately notify doctormedica.co in order that we may shut
                  down the account.
                </span>
              </Box>
            </Box>
            <Box>
              <Box>
                <b>6. Limitations of Service.</b>
                <span>
                  {" "}
                  The doctormedica.co web site is available on an "as-is,
                  as-available" basis, and we will not be responsible or liable
                  for any damage, loss, costs or otherwise if you are unable to
                  access the web site or experience any delay in the processing
                  of any transaction for any reason.
                </span>
              </Box>
            </Box>
            <Box>
              <Box>
                <p>
                  <b>7. Disclaimer.</b>
                  <span>
                    {" "}
                    doctormedica.co takes reasonable steps to protect the web
                    site and to be able to process your requests through your
                    customer account, and/or through our toll-free telephone
                    number and toll-free facsimile number. doctormedica.co makes
                    no representations or warranties, express or implied, with
                    respect to the web site, and without limitation, disclaims
                    the implied warranties of merchantability and fitness for a
                    particular purpose, usefulness, non-infringement,
                    completeness, accuracy, and reliability. All risks
                    associated with use of the web site shall be born by you
                    exclusively.
                  </span>
                </p>
                <p>
                  <span>&nbsp;</span>
                </p>
                <p>
                  <span>
                    IN NO EVENT WILL DOCTORMEDICA.COM BE LIABLE FOR ANY DIRECT,
                    INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF
                    THE USE OR INABILITY TO USE THE WEBSITE, FOR INTERRUPTED
                    SERVICE, OR FOR DAMAGE THAT OCCURS TO COMPUTER SYSTEMS AND
                    OTHERWISE THROUGH THE USE OF THE WEBSITE.
                  </span>
                </p>
              </Box>
            </Box>
            <Box>
              <Box>
                <b>8. Intellectual Property.</b>
                <span>
                  {" "}
                  All trade-marks and content of the web site are used under
                  license by doctormedica.co and may not be copied or reproduced
                  in any manner, in whole or in part.
                </span>
              </Box>
            </Box>
            <Box>
              <Box>
                <b>{`9. Links. `}</b>
                <span>
                  doctormedica.co may provide links to other third-party web
                  sites and is not responsible for the content, accuracy or
                  opinions expressed on such third-party web sites, and such web
                  sites are not investigated, monitored or checked for accuracy
                  or completeness by us. Inclusion of any linked web site on our
                  web site does not imply approval or endorsement of the linked
                  web site by us. If you decide to leave our web site and access
                  these third party sites, you do so at your own risk. Please
                  note that use of third party web sites may be governed by such
                  third parties' terms of use and privacy policies.
                </span>
              </Box>
            </Box>
            <Box>
              <Box>
                <b>{`10. Termination of Customer Account. `}</b>
                <span>
                  doctormedica.co reserves the right to terminate your customer
                  account.
                </span>
              </Box>
            </Box>
            <Box>
              <Box>
                <b>{`11. Severability. `}</b>
                <span>
                  Should any provision set out in this web site policy be
                  determined to be invalid or unenforceable in any jurisdiction,
                  such provision shall, as to that jurisdiction, be ineffective
                  only to the extent of such invalidity or unenforceability and
                  shall be severed from the balance of this document without
                  invalidating or affecting the remaining provisions, which
                  remaining provisions shall continue in full force and effect.
                </span>
              </Box>
            </Box>
            <Box>
              <Box>
                <b>{`12. Entire Agreement. `}</b>
                <span>
                  This Web site Use Policy constitutes a legally binding
                  agreement with doctormedica.co with respect to the matters
                  dealt with herein.
                </span>
              </Box>
            </Box>
            <Box>
              <Box>
                <b>{`13. Amendments. `}</b>
                <span>
                  doctormedica.co reserves the right to amend this Web site Use
                  Policy from time to time, and will provide notice of any
                  amendments on its web site It is the obligation of users of
                  the web site to review the Web site Use Policy on an ongoing
                  basis.
                </span>
              </Box>
            </Box>
            <Box>
              <Box>
                <b>{`14. Age of Majority. `}</b>
                <span>
                  In order to use the web site and establish a customer account,
                  all users must be of the age of majority in the relevant
                  jurisdiction and have the necessary licensing to purchase the
                  products offered on this site.
                </span>
              </Box>
            </Box>
            <Box>
              {isSyntechUser && (
                <VStack alignItems="flex-start">
                  <Text fontWeight={700}>{`Jurisdiction`}</Text>
                  <span>
                    These Terms and Conditions are regulated and interpreted in
                    accordance with the legal provisions of Hungary, and any
                    conflicts arising from these terms shall be exclusively
                    handled by the courts of Hungary, where the corporation is
                    based.
                  </span>
                  <Box fontSize="14px">
                    <Text>Syntech Solutions Kft.</Text>
                    <Text>Kinizsi Utca 20. Fz. 1 Ajto</Text>
                    <Text>Hungary, Budapest, 1203</Text>
                  </Box>
                </VStack>
              )}
            </Box>
          </VStack>
        </Box>
      </Layout>
    </>
  );
};

export const Head = () => (
  <SEO
    title="Doctor Medica - Website Use Policy"
    description="Doctor Medica offers a website usage policy that should be reviewed by website visitors prior to the use of the website."
  />
);

export default UsePolicy;
